import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [

  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'games',
    loadChildren: () => import('./games/games.module').then( m => m.GamesPageModule)
  },
  {
    path: 'real-games',
    loadChildren: () => import('./real-games/real-games.module').then( m => m.RealGamesPageModule)
  },
  {
    path: 'browser',
    loadChildren: () => import('./browser/browser.module').then( m => m.BrowserPageModule)
  },
  {
    path: 'wallet',
    loadChildren: () => import('./wallet/wallet.module').then( m => m.WalletPageModule)
  },
  {
    path: 'real-game-open',
    loadChildren: () => import('./real-game-open/real-game-open.module').then( m => m.RealGameOpenPageModule)
  },
  {
    path: 'set-up',
    loadChildren: () => import('./set-up/set-up.module').then( m => m.SetUpPageModule)
  },
  {
    path: 'bloked',
    loadChildren: () => import('./bloked/bloked.module').then( m => m.BlokedPageModule)
  },
  {
    path: 'console',
    loadChildren: () => import('./console/console.module').then( m => m.ConsolePageModule)
  },
  {
    path: 'terminal-report',
    loadChildren: () => import('./terminal-report/terminal-report.module').then( m => m.TerminalReportPageModule)
  },
  {
    path: 'sports',
    loadChildren: () => import('./sports/sports.module').then( m => m.SportsPageModule)
  },  {
    path: 'jackpot',
    loadChildren: () => import('./jackpot/jackpot.module').then( m => m.JackpotPageModule)
  },



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
