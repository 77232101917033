/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/dot-notation */
import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'src/services/general.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  newUrl = '';
  auxId = null;

  constructor(public generalService: GeneralService, private router: Router) {}

  ngOnInit() {
    //this.generalService.sendToMain({type:'on-ionic-hello', data: 'header'});
    //this.generalService.state = 'browser'; // esto lo dejo por las dudas (Chinu)
    window.addEventListener('on-enter-keydown',  e => {
      if(this.newUrl === '') {return;}
      this.onBrowser('on-search-url');
    });
  }

  // navegateTo - A DONDE VA:
  onButton() {
    if(this.generalService.terminalStatus ==='SUSPENDED'  || this.generalService.terminalStatus ==='DISCONNECTED') {return;}
    let navegateTo = 'wallet';
    switch (this.generalService.terminalMode) {
      case 'KIOSKI_MODE':
        if(this.generalService.state==='home') {
          navegateTo = 'wallet'; // buttonHeaderText = 'TRANSFERIR DINHEIRO'
        }else {
          navegateTo = 'home'; // buttonHeaderText = 'VOLTAR'
        }
      break;
      case 'GAME_MODE':
        if(this.generalService.state==='wallet') {
          if(this.generalService.enableGames){
            navegateTo = 'real-games';// buttonHeaderText = 'VOLTAR'
          } else {
            navegateTo = 'sports';
          }
        } else {
          navegateTo = 'wallet'; //buttonHeaderText = 'TRANSFERIR DINHEIRO'
        }
      break;
    }

    /*let exchangeOption = this.generalService.state ==='home'  || (this.generalService.state !== 'home' && this.generalService.terminalMode !== 'KIOSKI_MODE' && this.generalService.state !== 'wallet');
    let navegateTo = 'home';
    //exchangeOption? this.generalService.sendToMain({type:'on-exchange', data: true}):this.generalService.sendToMain({type:'on-navigate-to', data: navegateTo});
    if(exchangeOption) {
      navegateTo = 'wallet';
    }*/
    //alert('exchangeOption '+ exchangeOption + ' / navegateTo ' + navegateTo + ' / state ' + this.generalService.state );


    //ESTO debe estar al final
    if(this.generalService.terminalReportShowing) {
      this.generalService.terminalReportShowing = false;
      if(this.generalService.terminalMode !== 'KIOSKI_MODE') {
        navegateTo = 'real-games';
      }
    }

    this.generalService.sendToMain({type:'on-navigate-to', data: navegateTo});
    this.clearInput();
  }

  onBrowser(browsertype) {
    if(this.generalService.terminalStatus ==='SUSPENDED'  || this.generalService.terminalStatus ==='DISCONNECTED') {return;}
    let aux;
    (browsertype==='on-search-url')? aux=this.newUrl: aux=true;
    this.generalService.sendToMain({type:browsertype, data: aux});
    this.clearInput();
  }

  clearInput() {
    this.auxId = setTimeout(() => {
      this.newUrl = '';
      clearTimeout(this.auxId);
      this.auxId = null;
    }, 800);
  }

}
