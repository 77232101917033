import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-virtual-keyboard',
  templateUrl: './virtual-keyboard.component.html',
  styleUrls: ['./virtual-keyboard.component.scss'],
})
export class VirtualKeyboardComponent implements OnInit {
  @Output() inputChanged = new EventEmitter<string>();
  @Output() clearClicked = new EventEmitter<void>();
  @Output() closeClicked = new EventEmitter<void>();
  @Output() enterClicked = new EventEmitter<void>();

  keyboardLayout: string[][] = [
    ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'],
    ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
    ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'],
    ['z', 'x', 'c', 'v', 'b', 'n', 'm']
  ];

  constructor() { }

  ngOnInit() {}

  addCharacter(character: string) {
    this.inputChanged.emit(character);
  }

  clearInput() {
    this.clearClicked.emit();
  }

  closeModal() {
    this.closeClicked.emit();
  }

  enter() {
    this.enterClicked.emit();
  }

}
