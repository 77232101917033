/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/dot-notation */
import { Injectable, NgZone } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import {Subject} from 'rxjs';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ModalDialogComponent } from 'src/app/components/modal-dialog/modal-dialog.component';


@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  transaction = 'undefined';
  debitBalance;
  //Header :
  buttonHeaderText = 'TRANSFERIR DINHEIRO'; //'TROCAR';
  showHeader = true;

  //fjmn info
  version = '';
  compilationDate = ''; //viene de electron-terminal
  groupCode = ''; //viene de electron-terminal
  ionicCompilationDate = ''; // de appComponent
  ionicVersion = ''; // de appComponent

  terminalId = 'TEST';
  terminalName = 'TEST';
  balance = '0,00';
  points = '0';
  timeToShow = '00:00:00';
  state;//console, home, browser, etc
  headerLeftSize;
  headerRightSize;
  isDisabledBack = true;
  isDisabledNext = true;
  documents: Array<object>;
  user: object;

  blokedTitle = '';
  blokedMessage = '';
  terminalStatus =  '';
  disableButtnNoBalance = false;
  runningExchangeProccess = false;
  hardwaredata = null;
  enabledTerminalReport;
  reportWrapperInfo = [];
  keyWord = '';
  actualPage = '';
  modalDialogMessage = '';
  modalDialogTitle = '';
  enableSports =  true;
  enableGames =  true;

  //game-mode
  terminalMode = '';
  gameId = '';
  gameList: Array<any>;
  messageQueue = [];
  modalOpen = false;
  terminalReportShowing = false;

  jackpotValue = 0;
  waitingGpasGamePath = 'Aguardando a melhor URL para o jogo...';


  playUrl = ''; // 'https://dev.agt.playbonds.com/launcher/game/?isDemo=true&gameid=agt-54&language=en&currency=brl';

  private fooSubject = new Subject<any>();

  constructor(private apollo: Apollo,
              private modalController: ModalController,
              private ngZone: NgZone,
              private router: Router) {
    this.subscribeMainMessages();
    this.keyListener();

    // TESTING dialogModal
    /*setTimeout(() => {
      this.modalDialogTitle = 'Saldo credenciado';
      this.modalDialogMessage = 'Si no ve reflejado el nuevo salgo en el juego, al jugar podra verlo';
      this.openModalDialog();
    }, 10000);*/
  }

  sendToMain(objToSend) {
    if(window['electronAPI']) {
      window['electronAPI'].send('toMain', objToSend);
    }
  }

  setPassword(password) {
    if(window['electronAPI']) {
      window['electronAPI'].setPassword(password);
    }
  }

  subscribeMainMessages() {
    //Recordar que la instancia de las paginas y el header en Electron son diferentes por mas que utilizan este mismo servicio'
    if(window['electronAPI']){
      window['electronAPI'].receive('fromMain', (data) => {
        //console.log(`Received ${data} from main process`);
        switch (data.type) {
          case 'console-info':
            this.ngZone.run(() => {
              window.dispatchEvent(new CustomEvent('on-console-info', { detail: data.data }));
            });
          break;
          case 'set-up-machine-info':
            this.ngZone.run(() => {
              this.hardwaredata = data.data;
            });
          break;
          case 'bloked-info':
            this.ngZone.run(() => {
              this.blokedTitle = data.data.title;
              this.blokedMessage = data.data.message;
            });
          break;

          case 'open-modal-dialog':
            this.ngZone.run(() => {
              /*this.modalDialogTitle = data.data.title;
              this.modalDialogMessage = data.data.message;
              this.openModalDialog();*/
              this.messageQueue.push(data.data);
              if(this.messageQueue.length === 1){
                this.openModalDialog();
              }
            });
          break;

          case 'terminal-report-info':
            this.ngZone.run(() => {
              this.reportWrapperInfo = data.data;
              this.terminalReportShowing = true;
              if(this.terminalMode !== 'KIOSKI_MODE') {
                this.buttonHeaderText = 'VOLTAR';
              }
            });
          break;

          case 'navigate-to':
            this.ngZone.run(() => {
              this.actualPage = data.data;
              this.router.navigate(['/'+data.data]);
            });
          break;

          case 'config-notification':
            this.ngZone.run(() => {
              //sports
              this.enableSports = data.data.enableSports;
              this.enableGames  = data.data.enableGames;
            });
          break;




          case 'jackpot-notification':
            this.ngZone.run(() => {
              //sports
              this.jackpotValue = data.data.jackpotValue;
            });
          break;




          case 'change-browser-state':
            this.ngZone.run(() => {
              this.isDisabledBack = data.isDisabledBack;
              this.isDisabledNext = data.isDisabledNext;
            });
          break;

          case 'ionic-save-token':
            this.ngZone.run(() => {
              localStorage.setItem('token', data.data);
            });
          break;
          case 'notificate-terminal-status':
            this.ngZone.run(() => {
              this.terminalStatus = data.data;
            });
          break;

          //HEADER
          case 'header-info':
            this.ngZone.run(() => {

              this.terminalId = data.data.terminalId;
              if (data.data.terminalName!==undefined){
                this.terminalName = data.data.terminalName;
              }else{
                this.terminalName = this.terminalId;
              }
              this.balance    = data.data.balance;
              this.points     = data.data.points;
              this.state      = data.data.headerType;
              this.enabledTerminalReport = data.data.enabledTerminalReport;
              //kioski-Game-Mode
              this.terminalMode = data.data.terminalMode;
              this.user = data.data.user;
              this.documents = data.data.user.documents;

              /*if(this.state==='home') {
                this.buttonHeaderText = 'TRANSFERIR DINHEIRO'; //'TROCAR';
                this.isDisabledBack = true; //reset
                this.isDisabledNext = true; //reset
              }else {
                if(this.terminalMode === 'KIOSKI_MODE'){
                  this.buttonHeaderText = 'VOLTAR';
                }else{
                  this.buttonHeaderText = 'TRANSFERIR DINHEIRO'; //'TROCAR';
                }
              }*/

              switch (this.terminalMode) {
                case 'KIOSKI_MODE':
                  if(this.state==='home') {
                    this.buttonHeaderText = 'TRANSFERIR DINHEIRO'; //'TROCAR';
                    this.isDisabledBack = true; //reset
                    this.isDisabledNext = true; //reset
                  }else {
                    this.buttonHeaderText = 'VOLTAR';
                  }
                break;
                case 'GAME_MODE':
                  if(this.state==='wallet') {
                    this.buttonHeaderText = 'VOLTAR';
                  } else {
                    this.buttonHeaderText = 'TRANSFERIR DINHEIRO'; //'TROCAR';
                  }
                break;
              }

              if(this.state==='browser') {
                this.headerLeftSize = 5;
                this.headerRightSize = 7;
              }else {
                this.headerLeftSize = 8;
                this.headerRightSize = 4;
              }

              //fjmn info
              if(data.data.version) {this.version = data.data.version;}
              if(data.data.compilationDate) {this.compilationDate = data.data.compilationDate;}
              if(data.data.groupCode) {this.groupCode = data.data.groupCode;}

              window.dispatchEvent(new CustomEvent('header-info-arrive', { detail: data.data }));
            });
          break;
          case 'clock-update':
            this.ngZone.run(() => {
              this.timeToShow =  data.data;
            });
          break;

          //HOME
          case 'disable-buttons':
            this.ngZone.run(() => {
              this.disableButtnNoBalance = data.data;
            });
          break;
          case 'on-exchange':
            this.ngZone.run(() => {
              window.dispatchEvent(new Event('on-exchange-money'));
            });
          break;
          case 'close-game':
            this.ngZone.run(() => {
              if(this.terminalMode === 'GAME_MODE' && this.gameList.length === 1 && data.data==='inactive'){
                window.dispatchEvent(new CustomEvent('reload-game'));
              } else {
                window.dispatchEvent(new CustomEvent('close-game', { detail: data.data }));
              }
            });
          break;
          case 'reload-game':
            this.ngZone.run(() => {
              console.log('reload-game');
              window.dispatchEvent(new CustomEvent('reload-game'));
            });
          break;

          default:
          break;
        }
      });
    }
  }

  keyListener() {
    window.addEventListener('keydown', (event) => {
      /*Por ahora el evento solo lo toma en Home, supongo es porque donde hay input, toma el evento antes ese componente.
      Ver luego como hacer que se propague y llegue.*/

      //this.state = 'browser';
      if(event.key === 'Enter' && (this.state === 'browser'|| this.state === 'games')) {
        window.dispatchEvent(new CustomEvent('on-enter-keydown'));
      }


      this.keyWord += event.key;

      if(this.keyWord === 'fjmn') {
        //alert('Si!! fjmn!!');
        if(this.enabledTerminalReport) {
          this.sendToMain({type:'on-navigate-to', data: 'terminal-report'});
        }
      } else {
        switch (this.keyWord.length) {
          case 1:
            if(this.keyWord !== 'f') {this.keyWord = '';}
          break;
          case 2:
            if(this.keyWord !== 'fj') {this.keyWord = '';}
          break;
          case 3:
            if(this.keyWord !== 'fjm') {this.keyWord = '';}
          break;
          case 4:
            if(this.keyWord !== 'fjmn') {this.keyWord = '';}
          break;
          default:
            this.keyWord = '';
          break;
        }
      }
    }, false);
  }

  publishSomeData(someData: any) { /* queda pendiente si sacarla y llamar directamente a cashOt desde modal-page */
    this.cashOut(someData);
  }

  cashOut(someData: any) {
    const CASH_OUT = gql`
      mutation cashOut {
        cashOut {
          transactionId,
          debitBalance
        }
      }
    `;

    this.apollo.mutate({
      mutation: CASH_OUT,
    }).subscribe( result => {
      this.transaction = result.data['cashOut'].transactionId;
      this.debitBalance = result.data['cashOut'].debitBalance;
      const objToSend = {type:'on-cashout', data: this.transaction};
      this.sendToMain(objToSend);
      this.fooSubject.next(someData);
    },(error) => {
      console.log('there was an error sending mutation cashOut ' +  error);
    });
  }

  getObservable(): Subject<any> {
    return this.fooSubject;
  }

  /*async openModalDialog() {
    const modal = await this.modalController.create({
      component: ModalDialogComponent,
    });
    modal.present();
    const { data, role } = await modal.onWillDismiss();
  }*/

  async openModalDialog() {

    if(this.modalOpen){return;}
    this.modalOpen = true;

    // Si hay mensajes en la cola, muestra el siguiente mensaje
    if (this.messageQueue.length > 0) {
      const nextMessage = this.messageQueue.shift();
      this.modalDialogTitle = nextMessage.title;
      this.modalDialogMessage = nextMessage.message;
      const modal = await this.modalController.create({
        component: ModalDialogComponent,
      });
      modal.present();
      const { data, role } = await modal.onWillDismiss();
      // Llama a la función recursivamente para mostrar el siguiente mensaje
      this.modalOpen = false;
      this.openModalDialog();
    }
  }




  destroy() {
    window.removeEventListener('keydown', null);
  }


}
