/* eslint-disable quote-props */
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GeneralService } from 'src/services/general.service';

@Component({
  selector: 'app-modal-page',
  templateUrl: './modal-page.component.html',
  styleUrls: ['./modal-page.component.scss'],
})
export class ModalPageComponent implements OnInit {

  @Input() title: string;
  @Input() value: number;
  @Input() terminal: string;
  @Input() transaction: string;
  @Input() btn1: string;
  @Input() btn2: string;



  constructor(public generalService: GeneralService,
              public modalController: ModalController) { }

  ngOnInit() {}

  onBtn1() {
    this.dismiss();
  }

  onBtn2() {
    this.dismiss();

    if(this.btn2 !== 'OK'){
      this.generalService.publishSomeData({
        foo: 'show the second modal'
      });
    }

  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    //alert('CERARRRRR MODALLLLLl');
    this.modalController.dismiss({
      'dismissed': true
    });
  }
}
