import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GeneralService } from 'src/services/general.service';

@Component({
  selector: 'app-modal-dialog',
  templateUrl: './modal-dialog.component.html',
  styleUrls: ['./modal-dialog.component.scss'],
})
export class ModalDialogComponent implements OnInit {
  colorStyle = 'enabledColorStyle';

  constructor(private modalController: ModalController,
              public generalService: GeneralService) {}

  cancel() {
    return this.modalController.dismiss(null, 'cancel');
  }

  ngOnInit() {}
}
