/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/naming-convention */
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  TerminalAPIURL: window['env']['TerminalAPIURL'] ||  'http://terminal-api.terminal-dev.147.124.216.139.xip.playbrandsgroup.com/graphql/',
  AltenarSDK: window['env']['AltenarSDK'] || 'https://sb2widgetsstatic-altenar2-stage.biahosted.com/altenarWSDK.js',
  CashIOTenantId: window['env']['CashIOTenantId'] || '49a04479-50c6-11ee-b769-0242ac110006',

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
