/* eslint-disable @typescript-eslint/dot-notation */
import { Component, OnDestroy, OnInit, HostListener, ViewChild, ElementRef, AfterViewInit  } from '@angular/core';
import { GeneralService } from 'src/services/general.service';
import { ModalController } from '@ionic/angular';
import { ModalPageComponent } from './components/modal-page/modal-page.component';
import { environment } from 'src/environments/environment';
import * as packageJson from '../../package.json';

interface PackageInfo {
  version: string;
  compilationDate: string;
  // otras propiedades que desees acceder
}

//const packageJson = require('../../package.json') as PackageInfo;
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('cashiosdk', { static: false }) sdk: ElementRef<HTMLCashioSdkElement>;

  modal: HTMLElement;
  sdkEnable = false;

  constructor(public generalService: GeneralService, public modalController: ModalController) {
    this.initPresentModal();
  }

  @HostListener('window:beforeunload', ['$event'])
  handleBeforeUnload(event: BeforeUnloadEvent) {
    console.log('La aplicación se está cerrando.');
    this.generalService.destroy();
  }

  ngAfterViewInit(): void {
    window.addEventListener('header-info-arrive', this.onAction.bind(this));
  }

  async onAction(e) {
    //alert(e.detail.user.id);
    if(!e.detail.user.id || e.detail.user.id===null){return;}
    if(this.sdkEnable){return;}
    await this.sdk.nativeElement.configure({
          apiPath: 'https://cashio.playbrandsgroup.com',
          customerId: (e.detail.user.id).toString(),
          //cpf: '4.4.4.4.4.4',
          tenantId: environment.CashIOTenantId,
          style: {
            buttoncolor:'#0d6efd',
            buttoncolortext: 'white',
            colormodal: 'white',
            colormodaltext: 'black',
          }
    });
    this.sdkEnable = true;
  }

  exchangeMoneyHandler = () => {
    this.onExchange();
  };

  ngOnInit() {
    window.addEventListener('on-exchange-money', this.exchangeMoneyHandler);

    const packageInfo = packageJson as unknown as PackageInfo;
    /*console.log('Version:', packageInfo.version);
    console.log('Compilation Date:', packageInfo.compilationDate);*/
    this.generalService.ionicVersion = packageInfo.version;
    this.generalService.ionicCompilationDate = packageInfo.compilationDate;
  }

  ngOnDestroy() {
    window.removeEventListener('on-exchange-money',  this.exchangeMoneyHandler);
    this.modal = null;
  }

  onExchange() {
    if(this.generalService.runningExchangeProccess){return;}
    this.presentModal({
      title: 'CONFIRMA PAGAMENTO?',
      value: this.generalService.balance,
      // eslint-disable-next-line max-len
      terminal: (this.generalService.terminalName && this.generalService.terminalName!=='')?this.generalService.terminalName:this.generalService.terminalId,
      transaction: '',
      btn1: 'CANCELAR',
      btn2: 'ACEITAR',
      didPresent:this.didPresent()
    }, true);
  }

  initPresentModal() {
    this.generalService.getObservable().subscribe((data) => {
      if(this.generalService.runningExchangeProccess){return;}
      this.presentModal({
        title: 'PAGAMENTO CONFIRMADO',
        value: this.generalService.debitBalance,
        // eslint-disable-next-line max-len
        terminal: (this.generalService.terminalName && this.generalService.terminalName!=='')?this.generalService.terminalName:this.generalService.terminalId,
        transaction: this.generalService.transaction,
        btn1: '',
        btn2: 'OK',
        didPresent:this.didPresent()
      }, false);
    });
  }

  async presentModal(componentProps: object, backdrop: boolean) {
    const modal = await this.modalController.create({
      component: ModalPageComponent,
      backdropDismiss: backdrop,
      cssClass: 'my-custom-class',
      componentProps: componentProps as object
    });

    modal.onDidDismiss().then(() => {
      this.didDismiss();
    });

    return await modal.present();
  }

  didDismiss() {
    this.generalService.runningExchangeProccess = false;
  }

  didPresent() {
    this.generalService.runningExchangeProccess = true;
    // eslint-disable-next-line radix
    /*if(parseInt(this.generalService.balance) === 0) {
      this.generalService.sendToMain({type:'on-navigate-to', data: 'home'});
    }*/
  }

}
